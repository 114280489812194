@import "constants";

$button-translate: translate(-50%, -50%);

@keyframes pulse {
  from {
    transform: $button-translate scale(1);
  }
  50% {
    transform: $button-translate scale(1.2);
  }
  to {
    transform: $button-translate scale(1);
  }
}

.pane-divider {
  position: absolute;
  height: 100%;
  width: 1em;
  right: -0.5em;
  z-index: 100;
  cursor: col-resize;

  &:hover {
    button {
      display: block;
    }
  }

  button {
    // the sans-serif typeface the rest of the buttons use has vertical
    // alignment issues for this button for some reason, so we'll stick with
    // the system default
    font-family: inherit;
    font-size: 1.5em;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: $button-translate;
    display: none;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    border: 2px solid;
    @include theme-aware("border-color", "link-default");
    @include theme-aware("background-color", "background-primary");
    @include theme-aware("color", "link-default");
    cursor: pointer;

    &.show {
      display: block;
    }

    &.pulsing {
      animation: pulse 1s ease-in-out infinite;
    }
  }

  .vertical-rule {
    position: absolute;
    height: 100%;
    border: 1px solid;
    border-color: $BLUE_COLOR;
    display: none;
    z-index: 1000;

    &.visible {
      display: block;
    }
  }
}

.dragging {
  .pane-divider {
    button {
      display: none;
    }
  }
}