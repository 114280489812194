@import "constants";

.command-select {
  position: relative;
  border-right: 1px solid;
  @include theme-aware("border-right-color", "divider-secondary");

  dl {
    position: absolute;
    width: 100vw;
    max-width: 450px;
    bottom: 24px;
    @include theme-aware("background-color", "background-secondary");
    border: 1px solid;
    @include theme-aware("border-color", "divider-secondary");
    padding: 1em;
    column-count: 2;
    column-gap: 1em;
    line-height: 1.35;
    @include theme-aware("color", "foreground-primary");
    z-index: 10000;
    font-size: 0.9em;

    dt {
      display: inline;
      font-weight: bold;
    }

    dd {
      margin-left: 1.6em;
      font-size: 0.8em;

      .description {
        margin-right: 0.25em;
        @include theme-aware("color", "foreground-secondary");
      }

      .separator {
        opacity: 0.5;
        margin: 0 0.25em;
      }
    }

    label {
      display: block;
      padding: 0.25em 0;
      break-inside: avoid;
    }

    input {
      margin-right: 0.25em;
      vertical-align: middle;
    }
  }

  .selected {
    font-size: 1.5em;
    margin: 0;
    font-weight: bold;
    color: inherit;
    background-color: inherit;
    border: 0;
    width: 100%;
    height: 100%;
    padding: 0 0.5em;
    display: block;
    line-height: 1.5;

    .arrow {
      float: right;
      margin-left: 0.5em;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
  }
}
