@import "constants";

.operation {
  position: relative;
  display: flex;
  border-top: 1px solid;
  border-bottom: 1px solid;
  @include theme-aware("border-color", "divider-secondary");

  &.error {
    @include theme-aware("color", "error-destructive");

    input {
      @include theme-aware("color", "error-destructive");
    }
  }

  & input,
  & button {
    font-family: $MONOSPACE;
    font-size: 1.4em;
    padding: 0.25em 0 0.25em 0.35em;
    margin: 0;
  }

  input[type=text] {
    width: 0;
    flex-grow: 1;
  }

  button.remove-operation {
    font-size: 1.75rem;
    padding: 0 0.4em;
    @include theme-aware("background-color", "background-primary");
    @include theme-aware("color", "foreground-primary");
    border: 0;
    cursor: pointer;

    &:hover {
      @include theme-aware("color", "error-destructive");
    }
  }

  .error {
    position: absolute;
    padding: 0.5em;
    transform: translateY(-100%);
    width: 100%;
    @include theme-aware("background-color", "background-primary");
    border-bottom: 1px solid;
    @include theme-aware("border-bottom-color", "divider-secondary");
    border-top: 1px solid;
    @include theme-aware("border-top-color", "divider-secondary");
    line-height: 1.25;
  }
}
