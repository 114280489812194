@import "constants";

output {
  display: block;
  @include theme-aware("background-color", "background-primary");

  pre {
    margin: 0;
    white-space: pre-wrap;
  }
}

ins {
  @include theme-aware("background-color", "insert-highlight");
}

del {
  @include theme-aware("background-color", "delete-highlight");
}

.output-container {
  &.error {
    output {
      opacity: 0.5;
    }
  }

  .placeholder {
    // note: we want to mimic the color of the input placeholder which does
    // not currently change based on light/dark theming
    color: $GRAY_COLOR;
    cursor: default;
  }

  .copy {
    &.copied {
      @include theme-aware("color", "success");
      @include theme-aware("border-color", "success");
    }
  }
}
