@import "constants";

html {
  box-sizing: border-box;

  &.dragging {
    cursor: col-resize;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: $MONOSPACE;
  height: 100%;
}

body {
  @include theme-aware("background-color", "background-secondary");
  @include theme-aware("color", "foreground-primary");

  &.loading {
    cursor: wait;
  }
}

a {
  text-decoration: none;
  @include theme-aware("color", "link-default");

  &:hover {
    text-decoration: underline;
  }
}

main {
  display: flex;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  flex-grow: 1;
}

textarea,
input {
  border: 0;
  border-radius: 0;
  @include theme-aware("background-color", "background-primary");
  @include theme-aware("color", "foreground-primary");

  &:focus {
    @include theme-aware("background-color", "background-input-focus");
  }
}

textarea,
output {
  flex-grow: 1;
  width: 100%;
  padding: 3rem 1em 1em;
  line-height: 1.35;
  resize: none;
  overflow-y: auto;
  border-top: 1px solid;
  @include theme-aware("border-top-color", "divider-secondary");
  margin-top: 0; // remove the margin Safari adds above textareas
  height: 100%; // Firefox needs this in addtion to the `top:0` / `bottom:0`
}

button {  
  font-family: $SANS;
  
  &:hover {
    filter: brightness(110%) contrast(150%);
  }
}

#root {
  display: flex;
  flex-direction: column;
}

.sans {
  font-family: $SANS;
}

.mono {
  font-family: $MONOSPACE;
}

.input-container,
.output-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.output-spacer {
  width: 10em;
  flex: none;
}