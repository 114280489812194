@import "constants";

@keyframes cursor-blink {
  0% {
      opacity: 0;
  }
  33%, 67% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

$padding: 0.35em;

.toolbar {
  position: relative;
  font-family: $SANS;
  font-size: 0.9em;
  padding: 0 $padding;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.5;
  height: $TOOLBAR_HEIGHT;
  // necessary to specify min-height in addition to height
  // https://stackoverflow.com/a/33269282
  min-height: $TOOLBAR_HEIGHT;
  overflow: hidden;

  &.expanded {
    height: auto;

    .expand-arrow {
      position: relative;
    }
  }

  & > * {
    margin: 0 0.5em;
  }

  button {
    font-size: 0.95em;
    @include theme-aware("color", "link-default");
    @include theme-aware("background-color", "background-primary");
    border: 1px solid;
    @include theme-aware("border-color", "link-default");
    padding: 0.25em 0.5em;
    border-radius: 0.25em;
    cursor: pointer;

    &.copied {
      @include theme-aware("color", "success");
      @include theme-aware("border-color", "success");
    }

    &.clear {
      @include theme-aware("color", "error-destructive");
      @include theme-aware("border-color", "error-destructive");
    }

    & + button {
      margin-left: 0.5em;
    }

    img {
      height: 1em;
      margin-right: 0.25em;
      vertical-align: text-bottom;
    }
  }

  input {
    margin: 0 4px 0 0;

    &[type=number] {
      padding: 0.25em 0.5em;
      margin-left: 0.5em;
      border: 1px solid;
      @include theme-aware("border-color", "divider-secondary");
    }
  }

  .option {
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: default;
    max-height: 35px;

    label {
      margin: 0 0.25em;
      max-height: 20px;
    }
  }

  .radio-group-label {
    margin-right: 0.25em;
  }

  .font-icon {
    vertical-align: middle;
    margin-bottom: 0.2rem;
  }

  .github-link {
    img {
      vertical-align: middle;
    }
  }

  .privacy-policy {
    font-size: 0.9em;
  }

  .expand-arrow {
    display: none;
    font-size: 1.4em;
    padding: 0 0.35em 0.05em; // bottom padding to vertically center the arrow
    position: absolute;
    right: 0;
  }
}

@media (max-width: 1120px) { // magic number, based on when toolbar items start wrapping to a second line
  .toolbar {
    padding-right: 2em;

    &.expanded {
      padding-bottom: $padding;
    }

    .expand-arrow {
      display: block;
    }
  }
}