// dimensions
$TOOLBAR_HEIGHT: 36px;

// fonts
$MONOSPACE: "SFMono-Regular", Consolas, Menlo, Courier, "Courier New", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$SANS: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// base colors
$BLUE_COLOR: #0075ff;
$GREEN_COLOR: #008c65;
$RED_COLOR: #d50500;
$GRAY_COLOR: #757575; // matches color of input placeholder on Chrome
$DARK_BG_COLOR: #151a20;

// theme-specific colors
$THEMES: (
  light: (
    background-primary: white,
    background-secondary: #fafafa,
    background-input-focus: lighten($BLUE_COLOR, 48%),
    insert-highlight: rgba(lighten($GREEN_COLOR, 25%), 0.5),
    delete-highlight: rgba(lighten($RED_COLOR, 35%), 0.5),
    foreground-primary: #222,
    foreground-secondary: $GRAY_COLOR,
    divider-primary: #222,
    divider-secondary: lightgray,
    link-default: darken($BLUE_COLOR, 15%),
    action: $BLUE_COLOR,
    success: $GREEN_COLOR,
    error-destructive: $RED_COLOR,
  ),
  dark: (
    background-primary: $DARK_BG_COLOR,
    background-secondary: lighten($DARK_BG_COLOR, 10%),
    background-input-focus: darken($BLUE_COLOR, 45%),
    insert-highlight: rgba(lighten($GREEN_COLOR, 15%), 0.5),
    delete-highlight: rgba(lighten($RED_COLOR, 10%), 0.5),
    foreground-primary: white,
    foreground-secondary: lighten($DARK_BG_COLOR, 60%),
    divider-primary: lighten($DARK_BG_COLOR, 50%),
    divider-secondary: #55667a,
    link-default: lighten($BLUE_COLOR, 20%),
    success: lighten($GREEN_COLOR, 20%),
    error-destructive: lighten($RED_COLOR, 25%),
  ),
);

/**
 * theme-aware - Change color of a css property based on the currently active theme
 * https://blog.prototypr.io/how-i-theme-my-react-app-with-sass-414e8f905541
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: #FFF;
 */
@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $THEMES {
    .theme-#{$theme-name} & {
      #{$key}: map-get(map-get($THEMES, $theme-name), $color);
    }
  }
}
